import { Box, Typography } from "@mui/material";
import { keyframes, styled } from "styled-components";

import React from "react";
import routes from "../../routes/routes";
import EventComponent from "../../pages/event/EventComponent";
import { useLocation } from "react-router-dom";

const fadeOutFromBottom = keyframes`
 0% {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
}
100% {
    transform: translate3d(0, 0, 0);
}
`;

export const Overlay = styled(Box)<{ fadeOut: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #15b5fc linear-gradient(45deg, #2196f3 30%, #21cbf3 90%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  animation: ${(props) =>
    props.fadeOut ? `${fadeOutFromBottom} 5s ease backwards` : "none"};
`;

const fadeUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const AnimatedText = styled(Typography)`
  font-family: "Daggersquare !important";
  font-weight: bold;
  color: white;
  text-align: center;
  display: flex;

  & > span {
    opacity: 0;
    animation: ${fadeUp} 0.9s ease forwards;
    animation-delay: calc(var(--word-index) * 0.5s);
  }
`;

export default function OverlayText() {
  const [showOverlay, setShowOverlay] = React.useState(true);
  // const [open, setOpen] = React.useState(false);
  const location = useLocation();

  // const handleCloseModal = () => {
  //   setOpen(false);
  // };

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShowOverlay(false);
      // if (location.pathname === routes.ROOT) {
      //   setOpen(true);
      // }
    }, 5000);

    return () => clearTimeout(timer);
  }, [location.pathname]);

  return (
    <>
      {showOverlay && (
        <Overlay fadeOut={!showOverlay}>
          <AnimatedText
            gap={{ xs: "8px", sm: "10px", md: "2rem" }}
            fontSize={{ xs: "30px", sm: "50px", md: "80px", lg: "110px" }}
            fontFamily={"Daggersquare !important"}
          >
            {"Be As Playful As a Child".split(" ").map((word, index) => (
              <span
                key={index}
                style={{ "--word-index": index } as React.CSSProperties} // Type assertion to avoid TS errors
              >
                {word}
              </span>
            ))}
          </AnimatedText>
        </Overlay>
      )}

      {/* <EventComponent open={open} handleClose={handleCloseModal} /> */}
    </>
  );
}
